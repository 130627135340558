
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-view-all-tile {
        width: 100%;
        height: 100%;
        background-color: $placeholder-background;
        position: relative;
        overflow: hidden;

        &__right-arrow {
            margin-top: $nu-spacer-0pt5;
            margin-left: $nu-spacer-1;
        }
    }
