
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-catalog-tile {
        width: 100%;
        background-color: $placeholder-background;
        position: relative;

        @include css-has-supported {
            &:has(.c-carousel__container.focus-visible:focus[data-focus-visible-added]),
            &:has(.c-catalog-tile__image-container-inner__link.focus-visible:focus[data-focus-visible-added]) {
                outline: $focus-outline;
            }
        }

        &__fade {
            opacity: 0;
            transition: opacity .5s ease;

            &--in-view {
                opacity: 1;
            }
        }

        &__image-and-info {
            position: relative;
        }

        &__image-container {
            position: relative;

            &::before {
                position: relative;
                display: block;
                content: "";
                width: 100%;
                padding-top: 150%;
                background-color: $placeholder-background;
            }
        }

        &__image-container-inner {
            position: absolute;
            top: 0;
            width: 100%;

            &__link.focus-visible:focus[data-focus-visible-added] {
                outline: 0;
            }
        }

        &__image {
            width: 100%
        }

        &__closet-badge {
            position: absolute;
            top: 0;
            right: 0;
        }

        &__info-section {
            position: relative;

            &__link {
                outline-offset: -4px;
            }
        }

        &__info-container {
            @include ellipsize;

            background-color: $nu-secondary;
            padding: $nu-spacer-1pt5;
            width: 100%;
            min-height: 40px;
            text-align: center;

            &--light {
                background-color: $nu-white;
            }

            @include breakpoint(medium) {
                padding: $nu-spacer-2;
            }
        }

        &__display-name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: $nu-spacer-1;
        }

        &__brand {
            color: $nu-gray;
        }

        &__product-label {
            display: flex;
            width: 100%;
            justify-content: center;

            &-brand,
            &-brand--mobile {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            &-brand {
                text-align: right;
                padding-right: $nu-spacer-1;
            }

            &-price {
                text-align: left;
                padding-left: $nu-spacer-1;
            }
        }
    }
