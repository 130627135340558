
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-action-badge {
        $this: &;

        box-sizing: content-box;
        cursor: pointer;
        height: $nu-spacer-4;
        width: $nu-spacer-4;
        padding: $nu-spacer-1;
        margin: 0;

        &--hide-padding {
            padding: 0;
        }

        @include breakpoint(medium) {
            padding: $nu-spacer-2;

            &--hide-padding {
                padding: 0;
            }
        }

        &,
        &__fill-container {
            display: inline-flex;
            align-items: center;
            justify-content: center;
        }

        @include non-touch-device {
            &:hover {
                #{$this}__fill-container {
                    border-color: $nu-primary;
                }
            }
        }

        &--small {
            height: 44px;
            width: 44px;

            #{$this}__fill-container {
                height: 28px;
                width: 28px;
            }
        }

         &--hide-count {
            height: $nu-spacer-4;
            width: $nu-spacer-4;

            #{$this}__fill-container {
                height: 100%;
                width: 100%;
                padding: 0;
                border-radius: 50%;
            }
        }

        &__icon,
        &__counter,
        &__minus {
            transition: all 0.2s ease;
        }

        &__counter {
            color: $nu-gray;
            margin-right: $nu-spacer-1;
        }

        &--active {
            #{$this}__fill-container {
                background-color: $nu-primary;
                border-color: $nu-primary;
            }

            #{$this}__counter {
                color: $nu-white;
            }

            #{$this}__icon {
                fill: $nu-white;
            }

            @include non-touch-device {
                &:not(.is-loading):hover {
                    #{$this}__icon {
                        display: none;
                    }

                    #{$this}__minus {
                        display: block;
                    }
                }
            }

            &__counter,
            &__minus {
                color: $nu-white;
            }

            &__icon {
                fill: $nu-white;
            }
        }

        &--inactive {
            #{$this}__fill-container {
                background-color: $nu-white;
            }
        }

        &.is-loading {
            cursor: default;

            #{$this}__counter {
                color: $nu-white;
            }

            #{$this}__fill-container {
                background-color: $nu-primary;
                border-color: $nu-primary;
            }

            #{$this}__icon {
                display: none;
            }

            #{$this}__loading-spinner {
                display: block;
            }
        }

        &__fill-container {
            height: $nu-spacer-4;
            border-radius: $nu-spacer-2;
            padding: 0 $nu-spacer-1pt5;
            border: 1px solid $nu-white;
            transition: all 0.2s ease;
        }

        &__loading-spinner {
            display: none;
            color: $nu-gray--light;
        }

        &__minus {
            display: none;
            width: $nu-spacer-2;
            height: 1px;
            background: $nu-white;
        }
    }
