
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-empty-catalog-tile {
        width: 100%;
        height: 100%;
        background-color: $placeholder-background;
        position: relative;
        overflow: hidden;

        &__image-container {
            &:before {
                position: relative;
                display: block;
                content: "";
                width: 100%;
                padding-top: 150%;
                background-color: $placeholder-background;
            }
        }

        &__image-container-inner {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
        }

        &__image {
            width: 100%
        }
    }
