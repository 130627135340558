
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-product-tray-header {
        $this: &;

        @include breakpoint(large) {
            margin-bottom: $nu-spacer-4;
        }

        &--below-header-content {
            margin-bottom: $nu-spacer-0pt5;

            #{$this}__headings {
                margin-bottom: 0;
            }
        }

        &__header-container {
            padding: 0 $nu-spacer-2;

            @include breakpoint(medium) {
                padding: 0 $nu-spacer-4;
            }

            @include breakpoint(large) {
                padding: 0 $nu-spacer-12;
                display: grid;
                grid-template-columns: auto 1fr auto;
                align-items: end;
            }

            &--full-bleed-desktop {
                @include breakpoint(large) {
                    padding: 0 $nu-spacer-8;
                }
            }
        }

        &__headings {
            margin-bottom: $nu-spacer-1pt5;

            @include breakpoint(large) {
                grid-column: 2;
                grid-row: 1;
                margin-bottom: 0;
            }
        }

        &__bottom {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: $nu-spacer-2;

            @include breakpoint(large) {
                display: contents;
            }
        }

        &__counter {
            white-space: nowrap;
            line-height: 22px;

            @include breakpoint(large) {
                grid-column: 1;
                grid-row: 1;
            }
        }

        &__browse-link {
            @include breakpoint(large) {
                grid-column: 3;
                grid-row: 1;
                text-align: right;
                margin-left: auto;
            }
        }
    }
