
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-product-tray-module {
        $this: &;
        position: relative;
        margin: 0 auto $nu-spacer-1;

        &__header-container {
            align-items: center;
            margin-bottom: $nu-spacer-2;
            padding: 0 $nu-spacer-2;

            @include breakpoint(large) {
                padding: 0 $nu-spacer-12;
                margin: 0 $nu-spacer-1 $nu-spacer-4;
            }
        }

        &__header {
            text-align: center;
            vertical-align: middle;
            padding-bottom: 0;

            &--mobile {
                padding: 0 $nu-spacer-2;

                @include breakpoint(large) {
                    display: none;
                }
            }

            &--desktop {
                display: none;

                @include breakpoint(large) {
                    display: block;
                }
            }
        }

        &__browse-link {
            padding-right: $nu-spacer-1;

            @include breakpoint(large) {
                grid-column-start: inherit;
                padding-right: 0;
            }
        }

        &__tile {
            position: relative;
            display: inline-block;
            width: 100%;
            vertical-align: top;
            text-align: center;
            background-color: $transparent;
            margin: 0;
        }

        &__counter {
            padding-left: $nu-spacer-1;
            white-space: nowrap;

            @include breakpoint(large) {
                padding-left: 0;
            }
        }

        &__carousel.is-flex .c-carousel__slide-container {
            display: flex;
        }

        &__blank-tile {
            height: 100%;
        }
    }
